import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { INikkeCharacterNode } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Switch from 'react-switch';
import lodash from 'lodash';

import './nikke-character-dynamic.scss';
import { Row, Col, OverlayTrigger, Popover, Accordion } from 'react-bootstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faHorseHead,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../modules/common/components/seo';
import { NikkeSpecialitiesArray } from '../modules/nikke/character/model/nikke-specialities-array';
import { RatingBox } from '../modules/common/components/rating-box';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { CategoryCard } from '../modules/cs/main/components/category-card';
import { NikkeCubeTable } from '../modules/nikke/common/components/nikke-cube-table';
import { NikkeSkill } from '../modules/nikke/common/components/nikke-skill';
import { NIKKECharacterRatings } from '../modules/nikke/data/character-ratings';
import { NikkeCharacter } from '../modules/nikke/common/components/nikke-character';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(NikkeSpecialitiesArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface INikkeCharacterNodes {
  nodes: INikkeCharacterNode[];
}

interface INikkeCharacterEntry {
  currentUnit: INikkeCharacterNodes;
}

interface IProps {
  data: INikkeCharacterEntry;
}

const NikkeCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRankings, setNewRankings] = useState(
    NIKKECharacterRatings.find((rankingCharacter) => {
      if (
        rankingCharacter.Slug === character.slug &&
        rankingCharacter.Treasure === 'No'
      )
        return true;
    })
  );
  const [showMaxedSkills, setShowMaxedSkills] = useState(true);
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-nikke-v2'}
      game="nikke"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Information and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section ">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong>{character.name}</strong> is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity} rarity
                  </strong>{' '}
                  {character.slug === 'red-hood' ? (
                    <>
                      <strong>Burst</strong>{' '}
                      <StaticImage
                        src="../images/nikke/icons/type_all_bg.png"
                        alt="Type All"
                      />
                    </>
                  ) : (
                    <>
                      {character.burstType === '1' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_1_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                      {character.burstType === '2' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_2_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                      {character.burstType === '3' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_3_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                    </>
                  )}{' '}
                  character from the <strong>{character.class}</strong> class,
                  who wields a <strong>{character.weapon}</strong>{' '}
                  {character.weapon === 'Assault Rifle' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_ar.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Minigun' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_minigun.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Rocket Launcher' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_rocket.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Shotgun' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_shotgun.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'SMG' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_smg.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Sniper Rifle' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_sniper.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Unknown' && (
                    <>
                      <span>{character.weapon}</span>
                    </>
                  )}{' '}
                  weapon and belongs to the <strong>{character.element}</strong>{' '}
                  <>
                    {character.element === 'Wind' && (
                      <StaticImage
                        src="../images/nikke/icons/wind_bg.png"
                        alt="Wind"
                      />
                    )}
                    {character.element === 'Water' && (
                      <StaticImage
                        src="../images/nikke/icons/water_bg.png"
                        alt="Water"
                      />
                    )}
                    {character.element === 'Electric' && (
                      <StaticImage
                        src="../images/nikke/icons/ele_bg.png"
                        alt="Electric"
                      />
                    )}
                    {character.element === 'Iron' && (
                      <StaticImage
                        src="../images/nikke/icons/iron_bg.png"
                        alt="Iron"
                      />
                    )}
                    {character.element === 'Fire' && (
                      <StaticImage
                        src="../images/nikke/icons/fire_bg.png"
                        alt="Fire"
                      />
                    )}
                  </>{' '}
                  element. She's part of the{' '}
                  <strong>{character.manufacturer}</strong>{' '}
                  {character.manufacturer === 'Elysion' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_elysion.png"
                        alt="Elysion"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Tetra' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_tetra.png"
                        alt="Tetra"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Missilis' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_missilis.png"
                        alt="Missilis"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Pilgrim' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_pilgrim.png"
                        alt="Pilgrim"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Abnormal' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_abnormal.png"
                        alt="Abnormal"
                      />
                    </>
                  )}{' '}
                  faction.
                </h2>
                <div className="char-intro">
                  {character.backstory ? (
                    <>{character.backstory.backstory}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Investment tips</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div
            className={`content-header with-switch rarity-${character.rarity}`}
          >
            <div>
              <FontAwesomeIcon icon={faSquare} width="18" /> Skills
            </div>
            <div className="custom-switch">
              <Switch
                checked={showMaxedSkills}
                onChange={() => setShowMaxedSkills(!showMaxedSkills)}
                onColor="#009EEC"
                offColor="#484950"
                className="switch"
              />
              Max level
            </div>
          </div>
          {character.skills && character.hideSkills !== true ? (
            <>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon  rarity-${character.rarity}`}
                        >
                          Normal Attack
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Normal Attack</p>
                          <p className="skill-type">{character.weapon}</p>
                        </div>
                      </div>
                      <div className={`additional-information ultimate`}>
                        <p>
                          Mode: <span>{character.controlMode}</span>
                        </p>
                        <p>
                          Ammo: <span>{character.ammoCapacity}</span>
                        </p>
                        <p>
                          Reload: <span>{character.reloadTime}s</span>
                        </p>
                      </div>
                      <div
                        className={`skill-description rarity-${character.rarity}`}
                      >
                        <>{renderRichText(character.basicAttack, options)}</>
                      </div>
                    </div>
                  </Col>
                  {character.skills.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${character.rarity}`}
                            >
                              {skill.slot}
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                              <p className="skill-type">{skill.type}</p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            {skill.type === 'Active' ? (
                              <p>
                                Cooldown: <span>{skill.cooldown}s</span>
                              </p>
                            ) : (
                              <p>
                                Cooldown: <span>-</span>
                              </p>
                            )}
                          </div>
                          <div
                            className={`skill-description rarity-${character.rarity}`}
                          >
                            {showMaxedSkills ? (
                              <>
                                {renderRichText(
                                  skill.descriptionLevel10,
                                  options
                                )}
                              </>
                            ) : (
                              <>
                                {renderRichText(
                                  skill.descriptionLevel1,
                                  options
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div
            className={`content-header with-switch rarity-${character.rarity}`}
          >
            <div>
              <FontAwesomeIcon icon={faSquare} width="18" /> Skills (Treasure)
            </div>
          </div>
          {character.skills && character.hasTreasure ? (
            <>
              <p>
                To unlock the skills below, you need to obtain the Special
                Treasure for the character.
              </p>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon  rarity-${character.rarity}`}
                        >
                          Normal Attack
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Normal Attack</p>
                          <p className="skill-type">{character.weapon}</p>
                        </div>
                      </div>
                      <div className={`additional-information ultimate`}>
                        <p>
                          Mode: <span>{character.controlMode}</span>
                        </p>
                        <p>
                          Ammo: <span>{character.ammoCapacity}</span>
                        </p>
                        <p>
                          Reload: <span>{character.reloadTime}s</span>
                        </p>
                      </div>
                      <div
                        className={`skill-description rarity-${character.rarity}`}
                      >
                        <>{renderRichText(character.basicAttack, options)}</>
                      </div>
                    </div>
                  </Col>
                  {character.skills.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${character.rarity}`}
                            >
                              {skill.slot}
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                              <p className="skill-type">{skill.type}</p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            {skill.type === 'Active' ? (
                              <p>
                                Cooldown: <span>{skill.cooldown}s</span>
                              </p>
                            ) : (
                              <p>
                                Cooldown: <span>-</span>
                              </p>
                            )}
                            <p>
                              Unlocks at: <span>{skill.phase}</span>
                            </p>
                          </div>
                          <div
                            className={`skill-description rarity-${character.rarity}`}
                          >
                            {renderRichText(skill.skillTreasure, options)}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>doesn't have a treasure yet.</strong> If they receive
                  one, we will update the section!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Specialties
          </div>
          <div className="specialities-list">
            {character.specialities ? (
              character.specialities.sort().map((speciality, idx) => {
                return <SpecialityItem key={idx} speciality={speciality} />;
              })
            ) : (
              <span className="no-spec">-</span>
            )}
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={3} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.cv.en ? character.cv.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.cv.jpn ? character.cv.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.cv.kr ? character.cv.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          {fullImage ? (
            <GatsbyImage image={fullImage} alt="" className="full-image" />
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>images aren't available yet.</strong> They will be added
                when the character is released.
              </p>
            </div>
          )}
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {!character.isReviewPending ? (
            <div className="section-analysis">
              {character.slug === 'scarlet-black-shadow' ||
              character.slug === 'mica-snow-buddy' ||
              character.slug === 'ludmilla-winter-owner' ||
              character.slug === 'tove' ||
              character.slug === 'crown' ||
              character.slug === 'grave' ||
              character.slug === 'rumani' ||
              character.slug === 'rosanna-chic-ocean' ||
              character.slug === 'red-hood' ||
              character.slug === 'ein' ||
              character.slug === 'd-killer-wife' ||
              character.slug === 'emilia' ||
              character.slug === 'rem' ||
              character.slug === 'elegg' ||
              character.slug === 'sakura-bloom-in-summer' ||
              character.slug === 'quiry' ||
              character.slug === 'kilo' ||
              character.slug === 'ade' ||
              character.slug === 'zwei' ||
              character.slug === 'quency-escape-queen' ||
              character.slug === 'bay' ||
              character.slug === 'trony' ||
              character.slug === 'soda-twinkling-bunny' ||
              character.slug === 'privaty-unkind-maid' ||
              character.slug === 'moran' ||
              character.slug === 'leona' ||
              character.slug === 'cinderella' ||
              character.slug === 'asuka-shikinami-langley' ||
              character.slug === 'rei-ayanami' ||
              character.slug === 'mari-makinami-illustrious' ||
              character.slug === 'alice-wonderland-bunny' ||
              character.slug === 'tia' ||
              character.slug === 'phantom' ||
              character.slug === 'rouge' ||
              character.slug === 'clay' ||
              character.slug === 'naga' ||
              character.slug === 'marciana' ? (
                <>
                  <div className="info-box">
                    <p>
                      <strong className={`rarity-${character.rarity}`}>
                        {character.name}
                      </strong>{' '}
                      <strong>has a dedicated review/guide available.</strong>{' '}
                      You can find it by following the link below.
                    </p>
                  </div>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    {character.slug === 'grave' && (
                      <CategoryCard
                        title="Grave review"
                        link="/nikke/guides/guide-grave"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_grave.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'cinderella' && (
                      <CategoryCard
                        title="Cinderella review"
                        link="/nikke/guides/guide-cinderella"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_cinderella.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rumani' && (
                      <CategoryCard
                        title="Rumani review"
                        link="/nikke/guides/guide-rumani"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rumani.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'phantom' && (
                      <CategoryCard
                        title="Phantom review"
                        link="/nikke/guides/guide-phantom"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_phantom.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'quency-escape-queen' && (
                      <CategoryCard
                        title="Quency: Escape Queen review"
                        link="/nikke/guides/guide-quency-escape-queen"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_quency_escape.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rouge' && (
                      <CategoryCard
                        title="Rouge review"
                        link="/nikke/guides/guide-rouge"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rouge.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'mari-makinami-illustrious' && (
                      <CategoryCard
                        title="Mari Makinami Illustrious review"
                        link="/nikke/guides/guide-mari-makinami"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_mari.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rei-ayanami' && (
                      <CategoryCard
                        title="Rei Ayanami review"
                        link="/nikke/guides/guide-rei-ayanami"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rei.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'asuka-shikinami-langley' && (
                      <CategoryCard
                        title="Asuka Shikinami Langley review"
                        link="/nikke/guides/guide-asuka"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_asuka.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'zwei' && (
                      <CategoryCard
                        title="Zwei review"
                        link="/nikke/guides/guide-zwei"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_zwei.webp"
                            alt="Zwei"
                          />
                        }
                      />
                    )}
                    {character.slug === 'ein' && (
                      <CategoryCard
                        title="Ein review"
                        link="/nikke/guides/guide-ein"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_ein.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rosanna-chic-ocean' && (
                      <CategoryCard
                        title="Rosanna Summer review"
                        link="/nikke/guides/guide-rosanna-summer"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rosanna_chic.webp"
                            alt="RosannaSummer"
                          />
                        }
                      />
                    )}
                    {character.slug === 'sakura-bloom-in-summer' && (
                      <CategoryCard
                        title="Sakura Summer review"
                        link="/nikke/guides/guide-sakura-summer"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_sakurasummer.webp"
                            alt="Sakura Summer"
                          />
                        }
                      />
                    )}
                    {character.slug === 'clay' && (
                      <CategoryCard
                        title="Clay review"
                        link="/nikke/guides/guide-clay"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_clay.webp"
                            alt="Clay"
                          />
                        }
                      />
                    )}
                    {character.slug === 'alice-wonderland-bunny' && (
                      <CategoryCard
                        title="Alice: Wonderland Bunny review"
                        link="/nikke/guides/guide-alice-bunny"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_alicebunny.webp"
                            alt="Alice: Wonderland Bunny"
                          />
                        }
                      />
                    )}
                    {character.slug === 'soda-twinkling-bunny' && (
                      <CategoryCard
                        title="Soda: Twinkling Bunny review"
                        link="/nikke/guides/guide-soda-bunny"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_sodabunny.webp"
                            alt="Soda Bunny review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'trony' && (
                      <CategoryCard
                        title="Trony review"
                        link="/nikke/guides/guide-trony"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_trony.webp"
                            alt="Trony review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'kilo' && (
                      <CategoryCard
                        title="Kilo review"
                        link="/nikke/guides/guide-kilo"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_kilo.webp"
                            alt="Kilo review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'bay' && (
                      <CategoryCard
                        title="Bay review"
                        link="/nikke/guides/guide-bay"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_bay.webp"
                            alt="Bay review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'crown' && (
                      <CategoryCard
                        title="Crown review"
                        link="/nikke/guides/guide-crown"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_crown.webp"
                            alt="Crown review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rem' && (
                      <CategoryCard
                        title="Rem review"
                        link="/nikke/guides/guide-rem"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rem.webp"
                            alt="Rem review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'marciana' && (
                      <CategoryCard
                        title="Marciana dedicated guide"
                        link="/nikke/guides/marciana-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_marc.jpg"
                            alt="Marciana review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'naga' && (
                      <CategoryCard
                        title="Naga dedicated guide"
                        link="/nikke/guides/naga-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_naga.jpg"
                            alt="Naga review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'tia' && (
                      <CategoryCard
                        title="Tia dedicated guide"
                        link="/nikke/guides/tia-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_tia.jpg"
                            alt="Tia review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'quiry' && (
                      <CategoryCard
                        title="Quiry dedicated guide"
                        link="/nikke/guides/quiry-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_quiry.jpg"
                            alt="Quiry review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'red-hood' && (
                      <CategoryCard
                        title="Red Hood dedicated guide"
                        link="/nikke/guides/red-hood-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_red.jpg"
                            alt="Red Hood review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'tove' && (
                      <CategoryCard
                        title="Tove dedicated guide"
                        link="/nikke/guides/tove-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_tove.jpg"
                            alt="Tove review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'ludmilla-winter-owner' && (
                      <CategoryCard
                        title="Ludmilla: Winter Owner dedicated guide"
                        link="/nikke/guides/ludmilla-winter-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_xlud.jpg"
                            alt="Ludmilla: Winter Owner review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'mica-snow-buddy' && (
                      <CategoryCard
                        title="Mica: Snow Buddy dedicated guide"
                        link="/nikke/guides/mica-snow-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_xmica.jpg"
                            alt="Mica: Snow Buddy review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'scarlet-black-shadow' && (
                      <CategoryCard
                        title="Scarlet: Black Shadow dedicated guide"
                        link="/nikke/guides/scarlet-shadow-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_scarlet.jpg"
                            alt="Scarlet: Black Shadow review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'leona' && (
                      <CategoryCard
                        title="Leona review"
                        link="/nikke/guides/guide-leona"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_leona.jpg"
                            alt="Leona review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'moran' && (
                      <CategoryCard
                        title="Moran review"
                        link="/nikke/guides/guide-moran"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_moran.jpg"
                            alt="Moran review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'privaty-unkind-maid' && (
                      <CategoryCard
                        title="Privaty: Unkind Maid review"
                        link="/nikke/guides/guide-privaty-maid"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_priv.jpg"
                            alt="Privaty: Unkind Maid review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'ade' && (
                      <CategoryCard
                        title="Ade review"
                        link="/nikke/guides/guide-ade"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_ade.jpg"
                            alt="Ade review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'elegg' && (
                      <CategoryCard
                        title="Elegg review"
                        link="/nikke/guides/guide-elegg"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_elegg.jpg"
                            alt="Elegg review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'd-killer-wife' && (
                      <CategoryCard
                        title="D: Killer Wife review"
                        link="/nikke/guides/guide-d-killer-wife"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_dkiller.jpg"
                            alt="D: Killer Wife review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'emilia' && (
                      <CategoryCard
                        title="Emilia review"
                        link="/nikke/guides/guide-emilia"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_emi.webp"
                            alt="Emilia review"
                          />
                        }
                      />
                    )}
                  </Row>
                  <div className={`content-header rarity-${character.rarity}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                  </div>
                  {!character.isReviewPending && character.review ? (
                    <div className="section-analysis">
                      <Row className="pros-cons">
                        <Col sm="12" md="6">
                          <div className="box pros">
                            <h5>Pros</h5>
                            <hr />
                            <div className="raw list">
                              {renderRichText(character.pros, options)}
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="box cons">
                            <h5>Cons</h5>
                            <hr />
                            <div className="raw list">
                              {renderRichText(character.cons, options)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="info-box">
                      <p>
                        <strong>
                          Pros and Cons for{' '}
                          <strong className={`rarity-${character.rarity}`}>
                            {character.name}
                          </strong>{' '}
                          aren't available yet.
                        </strong>{' '}
                        They will be added soon.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className={`review raw`}>
                  {character.slug === 'ram' ||
                  character.slug === '2b' ||
                  character.slug === 'rapunzel-pure-grace' ||
                  character.slug === 'a2' ||
                  character.slug === 'misato-katsuragi' ||
                  character.slug === 'pascal' ||
                  character.slug === 'aqua-marine-helm' ||
                  character.slug === 'sparkling-summer-anis' ||
                  character.slug === 'noir' ||
                  character.slug === 'blanc' ||
                  character.slug === 'dorothy' ||
                  character.slug === 'nihilister' ||
                  character.slug === 'bay-goddess-mary' ||
                  character.slug === 'nihilister' ? (
                    <>
                      {character.review ? (
                        <>{renderRichText(character.review, options)}</>
                      ) : (
                        <div className="info-box">
                          <p>
                            <strong>
                              Review for{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              isn't available yet.
                            </strong>{' '}
                            It will be added soon.
                          </p>
                        </div>
                      )}
                      {!character.isReviewPending && character.review ? (
                        <div className="section-analysis">
                          <Row className="pros-cons">
                            <Col sm="12" md="6">
                              <div className="box pros">
                                <h5>Pros</h5>
                                <hr />
                                <div className="raw list">
                                  {renderRichText(character.pros, options)}
                                </div>
                              </div>
                            </Col>
                            <Col sm="12" md="6">
                              <div className="box cons">
                                <h5>Cons</h5>
                                <hr />
                                <div className="raw list">
                                  {renderRichText(character.cons, options)}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="info-box">
                          <p>
                            <strong>
                              Pros and Cons for{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              aren't available yet.
                            </strong>{' '}
                            They will be added soon.
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="info-box">
                        <p>
                          As it has been a long time since the initial analysis
                          of{' '}
                          <strong className={`rarity-${character.rarity}`}>
                            {character.name}
                          </strong>{' '}
                          and in light of the ever-evolving meta, the dedicated
                          review has been hidden.{' '}
                          {newRankings.Story_LD === 'SSS' ||
                          newRankings.Story_LD === 'SS' ||
                          newRankings.Story_LD === 'S' ||
                          newRankings.Story_LD === 'A' ||
                          newRankings.Story_HD === 'SSS' ||
                          newRankings.Story_HD === 'SS' ||
                          newRankings.Story_HD === 'S' ||
                          newRankings.Story_HD === 'A' ||
                          newRankings.Boss === 'SSS' ||
                          newRankings.Boss === 'SS' ||
                          newRankings.Boss === 'S' ||
                          newRankings.Boss === 'A' ? (
                            <>
                              However,{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              still{' '}
                              <strong>displays remarkable performance</strong>{' '}
                              in Story and/or Bosses. For more information,
                              visit our{' '}
                              <Link to="/nikke/guides/meta-teams">
                                Meta teams
                              </Link>{' '}
                              guide.
                            </>
                          ) : (
                            <>
                              Furthermore,{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              <strong>
                                has fallen out of fashion when it comes to PVE{' '}
                                {newRankings.PVP != 'SSS' &&
                                  newRankings.PVP != 'SS' &&
                                  newRankings.PVP != 'S' &&
                                  'and PVP '}
                                content
                              </strong>
                              . Consider using other higher-ranked units from
                              our tier list if possible.
                            </>
                          )}{' '}
                          {newRankings.PVP === 'SSS' ||
                          newRankings.PVP === 'SS' ||
                          newRankings.PVP == 'S' ? (
                            <>
                              The character <strong>is part of the meta</strong>{' '}
                              in PVP. For more information, visit our{' '}
                              <Link to="/nikke/guides/pvp-sp-arena">
                                PVP meta
                              </Link>{' '}
                              guide.
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong>
                  Review for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  isn't available yet.
                </strong>{' '}
                It will be added soon.
              </p>
            </div>
          )}
          {character.slug === 'laplace' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review (Treasure)
              </div>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong className="treasure">(Treasure)</strong> has a
                  dedicated review/guide available. You can find it by following
                  the link below.
                </p>
              </div>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Laplace (Treasure) review"
                  link="/nikke/guides/guide-laplace-treasure"
                  image={
                    <StaticImage
                      src="../images/nikke/categories/category_laplace_tr.webp"
                      alt="Laplace (Treasure)"
                    />
                  }
                />
              </Row>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                (Treasure)
              </div>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Good Burst Generation (slightly below Clip SG).
                          </li>
                          <li>
                            Hits hard, and will be even stronger if she can
                            pierce.
                          </li>
                          <li>
                            True Damage helps her a lot on high penalty stages
                            and in Raids.
                          </li>
                          <li>Her burst laser has pin point accuracy.</li>
                          <li>The Ark's Hero!</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            She requires a lot of investment, even more than top
                            meta DPS now to even work (Favorite Item System).
                          </li>
                          <li>
                            Her damage is only on okay level without pierce.
                          </li>
                          <li>
                            Her burst needs 5 full charge S1, which means you
                            mostly won't burst her first.
                          </li>
                          <li>
                            Can't cover during burst, literally Do or Die. Very
                            harsh quality to have for High Deficit Campaign.
                          </li>
                          <li>
                            Her burst's fire rate is affected by DPS, just like
                            any MG. Low FPS = Low DPS.
                          </li>
                          <li>Window Breaker...</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {character.slug === 'diesel' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review (Treasure)
              </div>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong className="treasure">(Treasure)</strong> has a
                  dedicated review/guide available. You can find it by following
                  the link below.
                </p>
              </div>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Diesel (Treasure) review"
                  link="/nikke/guides/guide-diesel-treasure"
                  image={
                    <StaticImage
                      src="../images/nikke/categories/category_diesel_tr.webp"
                      alt="Diesel (Treasure)"
                    />
                  }
                />
              </Row>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                (Treasure)
              </div>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>High buff and taunt uptime.</li>
                          <li>
                            Good against stages (mostly Boss stages) where chip
                            damage is prevalent.
                          </li>
                          <li>
                            Gives ▲Pierce Damage, which is usually an undiluted
                            buff.
                          </li>
                          <li>
                            Can reduce reloading time overall for teammates by
                            refilling magazines.
                          </li>
                          <li>
                            Almost immortal during burst (she can only die to
                            one-shots).
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>Must burst to be near immortal.</li>
                          <li>
                            Won't survive in High Deficit against high damage
                            Raptures.
                          </li>
                          <li>
                            Buffs are strong but very niche, and B2 competition
                            is kinda fierce right now.
                          </li>
                          <li>
                            Can't protect team well against AoE Wiping like
                            Scarlet's burst.
                          </li>
                          <li>
                            MGs are slow in generating burst gen, and feed burst
                            gen in return to opponent Jackal or Scarlet.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {character.slug === 'exia' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review (Treasure)
              </div>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong className="treasure">(Treasure)</strong> has a
                  dedicated review/guide available. You can find it by following
                  the link below.
                </p>
              </div>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Exia (Treasure) review"
                  link="/nikke/guides/guide-exia-treasure"
                  image={
                    <StaticImage
                      src="../images/nikke/categories/category_exia_tr.webp"
                      alt="Exia (Treasure)"
                    />
                  }
                />
              </Row>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                (Treasure)
              </div>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>Possesses innate ATK buffs.</li>
                          <li>
                            Acts as a DPS + support hybrid, providing ATK buffs
                            to allies while dealing decent damage herself.
                          </li>
                          <li>
                            All of her buffs are of high value and have
                            permanent uptime.
                          </li>
                          <li>
                            Inflicts Damage-taken debuff, one of the best
                            debuffs, on enemies, which allows herself and her
                            team to do more damage,
                          </li>
                          <li>
                            20s B1 DPS, allowing her to recieve twice the ATK
                            buffs from Crown compared to other B3 DPS.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Manual-intensive if one wants to reach her DPS
                            potential.
                          </li>
                          <li>
                            Supporting ATK buffs only buff Electric units.
                          </li>
                          <li>
                            On non electric-weak bosses, she will have a hard
                            time fitting into the top meta teams.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {character.slug === 'frima' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review (Treasure)
              </div>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong className="treasure">(Treasure)</strong> has a
                  dedicated review/guide available. You can find it by following
                  the link below.
                </p>
              </div>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Frima (Treasure) review"
                  link="/nikke/guides/guide-frima-treasure"
                  image={
                    <StaticImage
                      src="../images/nikke/categories/category_frima_tr.webp"
                      alt="Frima (Treasure)"
                    />
                  }
                />
              </Row>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                (Treasure)
              </div>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Ability to heal every 5 seconds with her Max HP
                            buff.
                          </li>
                          <li>One of the only units to have True Damage ▲.</li>
                          <li>
                            Massive value and high uptime on her True Damage
                            buffs.
                          </li>
                          <li>Auto-friendly SR unit.</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Expensive. Needs Phase 3 treasure to be relevant.
                          </li>
                          <li>Niche. Only buffs True Damage DPS.</li>
                          <li>
                            'Sleepy' stacks need to be stacked on the same
                            target, and the 6th shot needs to be hit on the same
                            target again to activate 'Wake Up', restricting her
                            use to Bossing only.
                          </li>
                          <li>
                            First burst needs to be delayed since you need to be
                            in Wake Up status to get the True Damage buffs.
                          </li>
                          <li>
                            If you want to heal with her constantly, you have to
                            stall her shot. If you stall her shot, you take
                            longer to get 'Wake Up'.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {character.slug === 'viper' && (
            <>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review (Treasure)
              </div>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong className="treasure">(Treasure)</strong> has a
                  dedicated review/guide available. You can find it by following
                  the link below.
                </p>
              </div>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Viper (Treasure) review"
                  link="/nikke/guides/guide-viper-treasure"
                  image={
                    <StaticImage
                      src="../images/nikke/categories/category_viper_tr.webp"
                      alt="Viper (Treasure)"
                    />
                  }
                />
              </Row>
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                (Treasure)
              </div>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>Unique Re-Enter Burst Stage II trait.</li>
                          <li>
                            Gains a lot of advantage than other SG against
                            bosses with core due to self Hit Rate buffs.
                          </li>
                          <li>DoT DPS with self Sustained Damage buffs.</li>
                          <li>
                            High value single target nuke when bursting, good
                            for PvP.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Skill 1 ATK buff still has very low uptime. No
                            innate ATK buffs apart from the first 15 seconds.
                          </li>
                          <li>
                            Expensive. Favorite Item needs to be Phase 3 for
                            PvE.
                          </li>
                          <li>
                            Phase 3 breaks most of the PvP Team Comps due to
                            Re-Enter Burst Stage II skill.
                          </li>
                          <li>
                            Split decision to choose Phase 2 for PvP or Phase 3
                            for PvE. Once you go to Phase 3, you cannot revert
                            back to Phase 2.
                          </li>
                          <li>
                            Highly situational unit, strict use in Water-Weak
                            Solo Raid Bosses, in which there are situations
                            where she may not be used anyways.
                          </li>
                          <li>
                            The gain is horrible compared to the cost since she
                            is still a mid tier DPS.
                          </li>
                          <li>
                            You're forced to run a 1-2-2 formation due to her
                            Re-Enter Burst Stage II skill.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {!character.isReviewPending ? (
            <div>
              {character.slug === 'red-hood' && (
                <>
                  <p>
                    Red Hood is a special character whose Burst can be used in
                    every stage and we have added her in every category to
                    showcase how she performs when you use her as a specific
                    stage character. The ratings below showcase her strength
                    based on how you use her.
                  </p>
                  <h6>B1</h6>
                </>
              )}
              <div className="detailed-ratings nikke">
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Story_LD}
                  ratingName="Story (low deficit)"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Story_HD}
                  ratingName="Story (high deficit)"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Boss}
                  ratingName="Bossing"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.PVP}
                  ratingName="PVP"
                />
              </div>
              {character.slug === 'laplace' && (
                <>
                  <h6>
                    With Favorite Treasure{' '}
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />
                  </h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="10"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="10"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="10" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="10" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'diesel' && (
                <>
                  <h6>
                    With Favorite Treasure{' '}
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />
                  </h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="9"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="8"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="9" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="7" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'exia' && (
                <>
                  <h6>
                    With Favorite Treasure{' '}
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />
                  </h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="8"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="8"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="9" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="6" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'frima' && (
                <>
                  <h6>
                    With Favorite Treasure{' '}
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />
                  </h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="6"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="6"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="8" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="6" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'viper' && (
                <>
                  <h6>
                    With Favorite Treasure{' '}
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />
                  </h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="7"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="7"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="9" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="9" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'red-hood' && (
                <>
                  <h6>B2</h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="5"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="5"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="6" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="8" ratingName="PVP" />
                  </div>
                  <h6>B3</h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="11" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="10" ratingName="PVP" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>ratings aren't available yet.</strong> They will be
                added soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Investment Tips
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skill Investment
          </div>
          <Accordion className="tier-list-accordion skills">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Skill Investment explained</Accordion.Header>
              <Accordion.Body>
                <p>
                  Here's the explanation for all the numbers and colors used in
                  the guide:
                </p>
                <ul>
                  <li>
                    <strong>10/5/7</strong> means upgrading Skill 1 to lvl 10,
                    Skill 2 to lvl 5, and Burst Skill to lvl 7,
                  </li>
                  <li>
                    <strong className="start">Yellow</strong> means the
                    immediate investment when u get the unit. There are 3
                    reasons for this:
                  </li>
                  <ul>
                    <li>
                      If the unit is Meta or High Priority, it will have a
                      starting investment of more than 1/1/1 (such as 4/4/4)
                      depending on what the unit provides.
                    </li>
                    <li>
                      If the unit is Medium or Low Priority, 1/1/1 will be
                      mentioned, meaning no immediate investment is required,
                      and you can invest in this character if you need to use it
                      according to the priority listed.
                    </li>
                    <li>
                      If we deem that a unit does not have usable skills to be
                      meta for any gamemode, no investment will be mentioned.
                    </li>
                  </ul>
                  <li>
                    <strong className="min">Blue</strong> means minimum
                    investment to take advantage of the Nikke's skills. There
                    are 2 reasons for this:
                  </li>
                  <ul>
                    <li>
                      Sometimes, minimum investments are breakpoints such as for
                      Noir or SAnis.
                    </li>
                    <li>
                      Sometimes, minimum investments are just the ideal way to
                      progress to upgrade that unit, before moving on to
                      Recommended and High.
                    </li>
                  </ul>
                  <li>
                    <strong className="rec">Red</strong> means recommended
                    investment for F2P players,
                  </li>
                  <li>
                    <strong className="high">Purple</strong> means maximum
                    recommended investment, in order to get the best out of the
                    unit. You should only try to reach these investments if you
                    have a surplus of Skill Books leftover.
                  </li>
                </ul>
                <p>More info:</p>
                <ul>
                  <li>
                    The skill investment list below features a lot of Level 4
                    and 7 because they are easier to understand. Any higher
                    levels will cost a new type of resource.
                  </li>
                  <li>
                    However, you may choose to upgrade to Level 5 if you need a
                    stronger effect instead of 4, as the increase in Blue Manual
                    cost from Level 4 to 5 is still low. The leap only starts
                    from Level 6 onward.
                  </li>
                  <li>
                    There is also a leap from Level 3 to 4, so if you want to
                    save a tiny bit of Blue Manuals, then leave at 3 instead of
                    4.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div className="nikke-skills-tips">
            <div className="nikke-skills-row header">
              <div className="column character">&nbsp;</div>
              <div className="column suggestions pve">PVE Investment</div>
              <div className="column suggestions pvp">PVP Investment</div>
              <div className="column priority">Priority</div>
            </div>
            <div className="nikke-skills-row data">
              <NikkeSkill char={character.slug} />
            </div>
          </div> */}
          {character.isReviewPending ? (
            <>
              <div className="info-box">
                <p>
                  <strong>
                    Skill investment tips for{' '}
                    <strong className={`rarity-${character.rarity}`}>
                      {character.name}
                    </strong>{' '}
                    aren't available yet.
                  </strong>{' '}
                  They will be added soon.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char={character.slug} />
                </div>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gear Investment
          </div>
          <Accordion className="tier-list-accordion skills">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Gear Investment explained</Accordion.Header>
              <Accordion.Body>
                <p>
                  This Overload (OL) Recommendations and Priority guide unravels
                  in detail everything you need about a character in terms of
                  priority, overload targets (effects), and order of
                  acquisition, alongside some notes to explain our thinking
                  process.
                </p>
                <p>
                  For OL targets and order of acquisition, we decided to divide
                  the target effects into several categories.
                </p>
                <ul>
                  <li>
                    <strong>Essential</strong>: With all gear combined, your
                    unit must have the roll(s) prescribed as a minimum
                    requirement. Example: Charge Speed on Alice. You cannot
                    compromise on these rolls.
                  </li>
                  <li>
                    <strong>Ideal</strong>: These rolls will improve your unit's
                    performance significantly. Aim for 2 lines of
                    Essential/Ideal per gear for maximum performance and the
                    best Return on Investment (ROI). If you get 3, you are
                    lucky. You can choose to only have 1 as well to save
                    materials. Example: Elemental Damage on Scarlet.
                  </li>
                  <li>
                    <strong>Passable:</strong> Low-priority line that has either
                    limited value or the impact is not as strong as other rolls.
                    Should this show up as a bonus together with a better line,
                    you can choose to keep them. Example: Charge Speed on Red
                    Hood.
                  </li>
                </ul>
                <p>
                  Overload effects are ordered by importance from Left to Right,
                  which means anything on the left provides more value than the
                  right. Effects that belong to the same group generate too
                  similar an impact to make a difference (and are sometimes
                  interchangeable). Try to follow this order as faithfully as
                  possible for maximum mileage.
                </p>
                <p>
                  <strong>Additional tips:</strong>
                </p>
                <ul>
                  <li>
                    Priority (PvE/PvP) isn't necessarily lower than Priority
                    (Universal), which has no tag/label. It just indicates that
                    this unit performs only in select types of content.
                  </li>
                  <li>
                    If a suggested effect has the number ×2 next to it, that
                    means you should get the effect on at least 2 gear. The same
                    idea applies to other numbers.
                  </li>
                  <li>
                    Focus on obtaining Essential(s) then you can start worrying
                    about optimizing your rolls. Units require these to
                    function, and the impact is too massive to ignore.
                  </li>
                  <li>
                    If a unit has no Essential(s), that means their performance
                    is not that dependent on OL, but they can still benefit from
                    good effects.
                  </li>
                  <li>
                    Remember that this is just a recommendation list, and
                    attaining perfection is impossible. You will have to make
                    some compromise, pick whichever rolls you desire the most,
                    and conserve as much material as possible.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {character.isReviewPending ? (
            <div className="info-box">
              <p>
                <strong>
                  Gear investment tips for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  aren't available yet.
                </strong>{' '}
                They will be added soon.
              </p>
            </div>
          ) : (
            <>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug={character.slug}
                        mode="icon"
                        showLabel
                        enablePopover
                        onlyName
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      {character.olInfo.Essential_1 ? (
                        <p>
                          <strong>{character.olInfo.Essential_1}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                      {character.olInfo.Essential_2 && (
                        <p>
                          <strong>{character.olInfo.Essential_2}</strong>
                        </p>
                      )}
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      {character.olInfo.Ideal_1 ? (
                        <p>
                          <strong>{character.olInfo.Ideal_1}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                      {character.olInfo.Ideal_2 && (
                        <p>
                          <strong>{character.olInfo.Ideal_2}</strong>
                        </p>
                      )}
                      {character.olInfo.Ideal_3 && (
                        <p>
                          <strong>{character.olInfo.Ideal_3}</strong>
                        </p>
                      )}
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      {character.olInfo.Passable ? (
                        <p>
                          <strong>{character.olInfo.Passable}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      {character.olInfo.Priority ? (
                        <>
                          {character.olInfo.Priority >= 11 && (
                            <p className={`prio prio-11`}>Meta</p>
                          )}
                          {character.olInfo.Priority >= 10 &&
                            character.olInfo.Priority < 11 && (
                              <p className={`prio prio-10`}>High</p>
                            )}
                          {character.olInfo.Priority === 9 && (
                            <p className={`prio prio-10`}>High (PVE)</p>
                          )}
                          {character.olInfo.Priority === 8 && (
                            <p className={`prio prio-10`}>High (PVP)</p>
                          )}
                          {character.olInfo.Priority === 7 && (
                            <p className={`prio prio-5`}>Medium</p>
                          )}
                          {character.olInfo.Priority === 6 && (
                            <p className={`prio prio-5`}>Medium (PVE)</p>
                          )}
                          {character.olInfo.Priority === 5 && (
                            <p className={`prio prio-5`}>Medium (PVP)</p>
                          )}
                          {character.olInfo.Priority === 4 && (
                            <p className={`prio prio-3`}>Low</p>
                          )}
                          {character.olInfo.Priority === 3 && (
                            <p className={`prio prio-3`}>Low (PVE)</p>
                          )}
                          {character.olInfo.Priority === 2 && (
                            <p className={`prio prio-3`}>Low (PVP)</p>
                          )}
                          {character.olInfo.Priority === 1 && (
                            <p className={`prio prio-1`}>Very Low</p>
                          )}
                          {character.olInfo.Priority === 0 && (
                            <p className="prio">-</p>
                          )}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>
                  </div>
                  <div className={`explanation`}>
                    {character.olInfo.Comments ? (
                      <p>{character.olInfo.Comments}</p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                {character.slug === 'laplace' && (
                  <div className="nikke-row">
                    <div className="nikke-skills-row over data">
                      <div className="column character">
                        <NikkeCharacter
                          slug={character.slug}
                          mode="icon-tier"
                          showLabel
                          enablePopover
                          onlyName
                          tierID={54}
                        />
                      </div>
                      <div className="column suggestions pve">
                        <p className="on-mobile">Essential</p>
                        <p>-</p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Ideal</p>
                        <p>
                          <strong>4× Elemental Damage</strong>
                        </p>
                        <p>
                          <strong>4× ATK</strong>
                        </p>
                        <p>
                          <strong>1-2× Max Ammo</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Passable</p>
                        <p>
                          <strong>4× Charge Speed</strong>
                        </p>
                      </div>
                      <div className="column priority">
                        <p className="on-mobile">Priority</p>
                        <p className={`prio prio-10`}>High</p>
                      </div>
                    </div>
                    <div className={`explanation`}>
                      <p className="on-mobile">Notes</p>
                      <p>
                        Elemental Damage and ATK are the basic food of any good
                        DPS in Nikke. 1-2× Max Ammo are good to reduce the
                        number of Reloads Laplace does. Meanwhile, if you manage
                        to obtain 21%+ Charge Speed through Overload Gear, she
                        can provide extremely high Burst Generation for your
                        team in PvP. This can possibly increase her rank in the
                        Burst Generation up to the Top 5, right above Clip
                        Shotguns!
                      </p>
                    </div>
                  </div>
                )}
                {character.slug === 'diesel' && (
                  <div className="nikke-row">
                    <div className="nikke-skills-row over data">
                      <div className="column character treasure">
                        <NikkeCharacter
                          slug={character.slug}
                          mode="icon-tier"
                          showLabel
                          enablePopover
                          onlyName
                          tierID={25}
                        />
                      </div>
                      <div className="column suggestions pve treasure">
                        <p className="on-mobile">Essential</p>
                        <p>-</p>
                      </div>
                      <div className="column suggestions pvp treasure">
                        <p className="on-mobile">Ideal</p>
                        <p>
                          <strong>2~3x Max Ammo</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp treasure">
                        <p className="on-mobile">Passable</p>
                        <p>-</p>
                      </div>
                      <div className="column priority treasure">
                        <p className="on-mobile">Priority</p>
                        <p className={`prio prio-5`}>Medium</p>
                      </div>
                    </div>
                    <div className={`explanation`}>
                      <p className="on-mobile">Notes</p>
                      <p>
                        Diesel only requires Max Ammo, the value of which
                        depends on your Treasure's Phase. The amount of ammo
                        will vary from 700 to 490, which is 133% ~ 88% ammo up.
                        If we consider mid-value Ammo lines, with the help of a
                        lvl7 Bastion cube, Diesel's ideal OL gear will need 2-3
                        Ammo.
                      </p>
                    </div>
                  </div>
                )}
                {character.slug === 'exia' && (
                  <div className="nikke-row">
                    <div className="nikke-skills-row over data">
                      <div className="column character treasure">
                        <NikkeCharacter
                          slug={character.slug}
                          mode="icon-tier"
                          showLabel
                          enablePopover
                          onlyName
                          tierID={36}
                        />
                      </div>
                      <div className="column suggestions pve treasure">
                        <p className="on-mobile">Essential</p>
                        <p>
                          <strong>MUST NOT HAVE MAX AMMO</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp treasure">
                        <p className="on-mobile">Ideal</p>
                        <p>
                          <strong>4× Elemental Damage</strong>
                        </p>
                        <p>
                          <strong>4× ATK</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp treasure">
                        <p className="on-mobile">Passable</p>

                        <p>
                          <strong>4× Charge Speed</strong>
                        </p>
                      </div>
                      <div className="column priority treasure">
                        <p className="on-mobile">Priority</p>
                        <p className={`prio prio-5`}>Medium</p>
                      </div>
                    </div>
                    <div className={`explanation`}>
                      <p className="on-mobile">Notes</p>
                      <p>
                        Elemental Damage and ATK better her DPS performance.
                        Niche DPS+Support for Electric Weak Raids. Last bullet
                        effect means she does not want Max Ammo effect on OL
                        gear! If she has Max Ammo on her gear, you cannot Full
                        Auto her. Charge Speed is a passable effect that helps
                        her if you want to auto her.
                      </p>
                    </div>
                  </div>
                )}
                {character.slug === 'frima' && (
                  <div className="nikke-row">
                    <div className="nikke-skills-row over data">
                      <div className="column character treasure">
                        <NikkeCharacter
                          slug={character.slug}
                          mode="icon-tier"
                          showLabel
                          enablePopover
                          onlyName
                          tierID={39}
                        />
                      </div>
                      <div className="column suggestions pve">
                        <p className="on-mobile">Essential</p>
                        <p>
                          <strong>-</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Ideal</p>
                        <p>
                          <strong>1-2× Max Ammo</strong>
                        </p>
                        <p>
                          <strong>4× Charge Speed</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Passable</p>
                        <p>
                          <strong>-</strong>
                        </p>
                      </div>
                      <div className="column priority">
                        <p className="on-mobile">Priority</p>
                        <p className={`prio prio-5`}>Low (PvE)</p>
                      </div>
                    </div>
                    <div className={`explanation`}>
                      <p className="on-mobile">Notes</p>
                      <p>
                        Frima does not require Overload Gear to function.
                        However, if you do Overload her, the following Priority
                        can be followed.
                      </p>
                    </div>
                  </div>
                )}
                {character.slug === 'viper' && (
                  <div className="nikke-row">
                    <div className="nikke-skills-row over data">
                      <div className="column character treasure">
                        <NikkeCharacter
                          slug={character.slug}
                          mode="icon-tier"
                          showLabel
                          enablePopover
                          onlyName
                          tierID={134}
                        />
                      </div>
                      <div className="column suggestions pve">
                        <p className="on-mobile">Essential</p>
                        <p>
                          <strong>-</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Ideal</p>
                        <p>
                          <strong>4x Hit Rate</strong>
                        </p>
                        <p>
                          <strong>4x Attack</strong>
                        </p>
                        <p>
                          <strong>4x Elemental Damage</strong>
                        </p>
                      </div>
                      <div className="column suggestions pvp">
                        <p className="on-mobile">Passable</p>
                        <p>
                          <strong>4x Ammo</strong>
                        </p>
                      </div>
                      <div className="column priority">
                        <p className="on-mobile">Priority</p>
                        <p className={`prio prio-5`}>Low (PvE)</p>
                      </div>
                    </div>
                    <div className={`explanation`}>
                      <p className="on-mobile">Notes</p>
                      <p>
                        Because Viper has a lot of Hit Rate already, OL Hit Rate
                        will help her reach 90% breakpoint easier and make her
                        deal damage like a MG unit. ATK and Ele are standard for
                        any DPS.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Cube Investment
          </div>
          {character.isReviewPending ? (
            <div className="info-box">
              <p>
                <strong>
                  Cube investment tips for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  aren't available yet.
                </strong>{' '}
                They will be added soon.
              </p>
            </div>
          ) : (
            <>
              {character.harmonyCubes ? (
                <>
                  <div className="cube-investment">
                    <div className="cube-table">
                      <div className="column">
                        <h5 className="pre">Pre-overload</h5>
                        <ul>
                          <li>
                            <NikkeCubeTable
                              cube={character.harmonyCubes.pre_1}
                            />
                          </li>
                          {character.harmonyCubes.pre_2 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.pre_2}
                              />
                            </li>
                          )}
                          {character.harmonyCubes.pre_3 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.pre_3}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="column">
                        <h5 className="post">Post-overload</h5>
                        <ul>
                          <li>
                            <NikkeCubeTable
                              cube={character.harmonyCubes.post_1}
                            />
                          </li>
                          {character.harmonyCubes.post_2 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.post_2}
                              />
                            </li>
                          )}
                          {character.harmonyCubes.post_3 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.post_3}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="raw list">
                      {character.harmonyCubesInfo && (
                        <>
                          {renderRichText(character.harmonyCubesInfo, options)}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="info-box">
                  <p>
                    <strong>
                      Cube investment tips for{' '}
                      <strong className={`rarity-${character.rarity}`}>
                        {character.name}
                      </strong>{' '}
                      aren't available yet.
                    </strong>{' '}
                    They will be added soon.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default NikkeCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | NIKKE | Prydwen Institute'}
      description={
        character.backstory
          ? character.backstory.backstory
          : 'One of the characters in Goddess of Victory: NIKKE.'
      }
      image={character.smallImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulNikkeCharacter(
      filter: { id: { eq: $contentfulId } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        ...NikkeCharacterFieldsFragment
      }
    }
  }
`;
